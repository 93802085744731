<template lang="pug">
div
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *

  div(:class="[currentFieldIsInvalid ? `${main_class} valid-error` : main_class]")
    q-input(
      filled,
      no-error-icon,
      v-model="currentField",
      :label-slot="false",
      :type="hidden_pass ? 'password' : 'text'",
      autocomplete="off",
      :for="name"
    )
      .show-password-icon(v-if="currentField", @click="hidden_pass = !hidden_pass")
        inline-svg(:src="require(`../../../../assets/icons/login/${hidden_pass ? 'closed-eye' : 'opened-eye'}.svg`)")
      q-icon.cancel-string-field(name="cancel", v-if="currentField", @click.stop="resetField()")

  span.valid-error-message(v-if="currentFieldIsInvalid") {{ customErrorMessage }}
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      depend_from: this.parentData.data[0].depend_from || {},
      req: this.parentData.data[0].require,
      watch: this.parentData.data[0].watch || {},
      rules: this.parentData.data[0].rules,
      valid_error_message: null,
      main_class: "form-field form-input",
      hidden_pass: true,
    };
  },

  computed: {
    required: {
      get() {
        return this.req;
      },
      set(value) {
        this.req = value;
      },
    },

    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          return form_field["field"];
        } else {
          return undefined;
        }
      },
      set(value) {
        let result = {};
        if (value && value.length > 0) {
          result["field"] = value;
          result["invalid"] = this.invalid(value);
        } else {
          result["invalid"] = this.invalid();
        }
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
        if (!result["invalid"]) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    customErrorMessage() {
      const invalidFieldsErrors = this.currentForm.invalid_fields_errors;

      return invalidFieldsErrors ? invalidFieldsErrors[this.name] : this.valid_error_message || "";
    },

    fieldDependency() {
      return this.checkDependency();
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},

    currentField(newVal, oldVal) {
      if (newVal && this.watch && this.watch["parents"] && this.watch["parents"].length > 0) {
        this.watch["parents"].forEach(el => {
          if (el["parent_params"]["fn"] === "passConfirmation") {
            let form_field = this.currentForm[el.parent];
            if (form_field) {
              this.passConfirmation(form_field["field"]);
            }
          }
        });
      }
    },
  },

  created() {
    // Allow backend to override default behavior by setting required: false
    if (this.required !== true && this.required !== false) {
      this.required = this.method === "create";
    }

    this.watchPasswordFieldParents();
  },

  beforeMount() {
    this.$store.commit("createFormField", { grid_name: this.grid, field: this.name });
    this.currentField = "";
  },

  methods: {
    setField(val) {
      // this.value = val
    },

    setRequire(val) {
      this.required = (val !== undefined && val.length > 0) || this.method === "create";
      let result = {};
      result["field"] = this.currentField;
      result["invalid"] = this.invalid(this.currentField);
      this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });

      this.$store.commit("resetFormFieldValue", {
        grid_name: this.grid,
        field: "invalid_fields",
        value: this.name,
      });
    },

    passConfirmation(parent) {
      let result = {};
      if ((this.currentField && parent && this.currentField !== parent) || (this.currentField && !parent)) {
        this.valid_error_message = this.notifies.pass_not_confirm;
        result["field"] = this.currentField;
        result["invalid"] = true;
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
      } else {
        result["field"] = this.currentField;
        result["invalid"] = this.invalid(this.currentField);
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
      }
    },

    resetField() {
      this.currentField = "";
    },

    invalid(val = undefined) {
      let result;

      if (this.required) {
        if (val && val.length > 0) {
          this.valid_error_message = null;
          result = false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          result = true;
        }
      } else {
        this.valid_error_message = null;
        result = false;
      }

      if (this.rules && this.rules.length > 0) {
        if (val && val.length > 0) {
          let messages = this.rules
            .map(rule => {
              let regex = new RegExp(rule["regex"]);
              if (!regex.test(val)) {
                return rule["error"];
              }
            })
            .filter(msg => ![undefined, null, ""].includes(msg));
          if (messages.length > 0) {
            this.valid_error_message = messages[messages.length - 1];
            result = true;
          }
        }
      }

      return result;
    },

    watchPasswordFieldParents() {
      if (this.watch && this.watch["parents"] && this.watch["parents"].length > 0) {
        this.watch["parents"].forEach(el => {
          this.$watch(
            () => {
              let form_field = this.currentForm[el.parent];
              if (form_field) {
                return form_field["field"];
              }
            },
            (newValue, oldValue) => {
              if (!el["parent_params"]["path"] && el["parent_params"]["fn"]) {
                this[el["parent_params"]["fn"]](newValue);
              }
            },
          );
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/string";
</style>
