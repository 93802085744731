<template lang="pug">
div
  .login-title.login-reset-password-title.row.justify-center
    span {{ loginLocales["password_reset_form.title"] }}

  q-form(@submit="onSubmit")
    .login-form.password-field.reset-password-field
      .login-field-label
        span {{ loginLocales["password_reset_form.fields.password.label"] }}

      .login-field-input
        q-input(
          filled,
          :label-slot="false",
          ref="passwordInput",
          v-model="password",
          :type="hiddenPass ? 'password' : 'text'",
          lazy-rules,
          no-error-icon,
          for="password-input",
          :rules="[val => (val && val.length >= 4) || loginLocales['fields_rules.not_less_4'], val => !checkForCyrillicSymbols(val) || loginLocales['fields_rules.not_cyrillic']]"
        )
          .show-password-icon(@click="hiddenPass = !hiddenPass")
            inline-svg(:src="require(`../../../assets/icons/login/${hiddenPass ? 'closed-eye' : 'opened-eye'}.svg`)")

    .login-form.password-field.reset-password-confirm-field
      .login-field-label
        span {{ loginLocales["password_reset_form.fields.confirm_password.label"] }}

      .login-field-input
        q-input(
          filled,
          :label-slot="false",
          ref="passwordConfirmInput",
          v-model="passwordConfirm",
          :type="hiddenPassConfirm ? 'password' : 'text'",
          lazy-rules,
          no-error-icon,
          for="password-confirm-input",
          :rules="[val => (val && val.length >= 4) || loginLocales['fields_rules.not_less_4'], val => !checkForCyrillicSymbols(val) || loginLocales['fields_rules.not_cyrillic']]"
        )
          .show-password-icon(@click="hiddenPassConfirm = !hiddenPassConfirm")
            inline-svg(
              :src="require(`../../../assets/icons/login/${hiddenPassConfirm ? 'closed-eye' : 'opened-eye'}.svg`)"
            )

    .recovery-form.button-group.reset-password-form-button-group.row.justify-center
      q-btn.login-submit(flat, no-caps, :label="loginLocales['password_reset_form.buttons.submit']", type="submit")
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Notify } from "quasar";

import backend from "@/api";
import { useStore } from "@/store";
import { loginLocales } from "@/services/useLocales";

import { useRouter } from "@/routing/router";

const props = defineProps({ resetPasswordToken: { type: String, default: "" } });
const emit = defineEmits(["setLoading", "passwordReset"]);

const store = useStore();
const router = useRouter();

const password = ref("");
const passwordConfirm = ref("");
const hiddenPass = ref("");
const hiddenPassConfirm = ref("");

const passwordInput = ref();
const passwordConfirmInput = ref();

const checkForCyrillicSymbols = password => {
  const regex = /[а-яА-Я]/;
  return regex.test(String(password));
};

const sendNewPassword = async params => {
  try {
    await backend.reset_password(store.state.paths["reset_password"], params);

    emit("passwordReset", false);

    Notify.create({ message: loginLocales.value["password_reset_form.notifies.updated"] });

    await router.replace({ name: "Login" });
  } catch (e) {
    const baseErrors = e.response.data.errors.base;
    const fieldsErrors = e.response.data.errors;

    let errorMessage = "";

    if (baseErrors) {
      errorMessage += baseErrors.map(el => el.message || el).join(". \n");
      Notify.create({ message: errorMessage });
    } else if (fieldsErrors) {
      Object.keys(fieldsErrors).forEach(e => {
        const fieldErrors = fieldsErrors[e];

        if (fieldErrors) {
          errorMessage += fieldErrors.map(el => `${el.message || el}`).join(". \n");
        }
      });

      Notify.create({ message: errorMessage });
    }
  }
};

const onSubmit = async () => {
  if (passwordInput.value && passwordConfirmInput.value) {
    passwordInput.value.validate();
    passwordConfirmInput.value.validate();

    if (!passwordInput.value.hasError && !passwordConfirmInput.value.hasError) {
      const params = {
        user: {
          reset_password_token: props.resetPasswordToken,
          password: password.value,
          password_confirmation: passwordConfirm.value,
        },
      };

      await sendNewPassword(params);
    }
  }
};

onMounted(() => {
  emit("setLoading", false);
});
</script>

<style lang="scss"></style>
