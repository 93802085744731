<template lang="pug">
.facility-tab
  .top
    .card.common-info
      .card-image.noselect
        img(:src="require(`../../../../assets/icons/object_pass/objects.svg`)")
      .card-separator
      .card-info
        .card-info-rows
          .card-info-row(v-for="(element, index) in metrics.fields", :key="`${element.label}_${index}`")
            span.title {{ element.label }}
            div(v-if="element.type === 'attachments'")
              a.field-value-attachment(
                v-for="attachment in element.value",
                :key="`${attachment.file_name} ${attachment.file_size}`",
                target="_blank",
                :href="attachment.url"
              ) {{ attachment.file_name }}
            div(v-else-if="element.type === 'link'")
              a.field-value-attachment(target="_blank", :href="element.value") {{ element.value }}
            div(v-else) {{ metricsCardValue(element) }}

  .bottom
    .card.expenses
      .card-label.noselect
        span {{ boards.expenses.title }}
      p {{ locales["ppr_object_passport"][current_locale]["plug"] }}

    .measuring-meters
      .card
        .card-label.noselect
          span {{ boards.measuring_meters.title }}
        .card-image.noselect
          img(:src="require(`../../../../assets/icons/object_pass/measuring_meters_${theme}.svg`)")
        .card-button

    .telemetry
      .card
        .card-label.noselect
          span {{ boards.telemetry.title }}
        .card-image.noselect
          img(:src="require(`../../../../assets/icons/object_pass/telemetry_${theme}.svg`)")
        .card-button
          q-btn(flat, no-caps, @click="openTelemetry()")
            template(slot="default")
              span {{ boards.telemetry.buttons[0].label }}

  .search
    query-filter(
      @search-all-filter-reset="searchAllFilterReset",
      @update-search-filter="updateSearchFilter",
      :parentData="{ grid, data: [{ label: searchLabel, name: 'query', type: 'search_all' }] }"
    )

  horizontal-filters.horizontal-filters(
    @filterResults="onFilterResults",
    :path="filtersPath",
    :fieldsPathParams="horizontalFiltersFieldsPathParams",
    :activeFilter="activeFilter",
    :iconsByTypes="iconsByTypes"
  )

  .objects_list(v-if="expansionItems.length === 1")
    q-list.expansion-list
      template(v-for="(item, i) in expansionItems[0].items")
        expansion-list-item(
          :key="item.id",
          :element="item",
          :level="1",
          :horizontalFilters="horizontalFiltersContent",
          :ref="el => (expansionItemRefs[i] = el)",
          :showEditButton="true",
          :hideEmptyValues="hideEmptyValues",
          @openEditForm="openEditForm"
        )
  .objects_list(v-else-if="expansionItems.length > 1")
    q-tabs(v-model="currentTab", dense, align="justify", narrow-indicator)
      q-tab(v-for="tab in expansionItems", :name="tab.type", :label="tab.label", :key="tab.template_id")
    q-tab-panels(v-model="currentTab", animated)
      q-tab-panel(v-for="(tab, tabIndex) in expansionItems", :name="tab.type", :key="`${tab.template_id}_${tabIndex}`")
        q-list.expansion-list
          template(v-for="(item, i) in tab.items")
            expansion-list-item(
              :key="item.id",
              :element="item",
              :level="1",
              :horizontalFilters="horizontalFiltersContent",
              :ref="el => (expansionItemRefs[i] = el)",
              :showEditButton="true",
              @openEditForm="openEditForm"
            )

  .objects_list.q-pa-xl.q-ma-xl(v-else)
    .row.justify-center.align-center.card-label
      span {{ locales.notifies[current_locale].no_options_list }}

  grid-modals(
    ref="modal",
    :parentData="{ grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, row: row, fromDashboard: true }"
  )
</template>

<script setup>
import { ref, onMounted, computed, provide } from "vue";
import { backend } from "@/api";
import { useStore } from "@/store";
import { useRouter } from "@/routing/router";
import { handleError } from "@/services/handleErrors";
import { locales, currentLocale } from "@/services/useLocales";
import { handleDateRow } from "@/services/reformatStrings";
import pluralize from "pluralize";
import queryFilter from "@/components/shared/filters/query_filter.vue";
import i18n from "@/plugins/vue-i18n";
import _ from "lodash";

import HorizontalFilters from "@/components/shared/general_components/horizontalFiltersList/HorizontalFilters";
import ExpansionListItem from "@/components/shared/general_components/expansionList/ExpansionListItem";
import GridModals from "@/components/shared/grid/gridModals";

const props = defineProps({
  objectId: { type: Number, default: -1 },
  templateId: { type: Number, default: -1 },
  boards: { type: Object, default: () => {} },
});

const emit = defineEmits(["set-title", "loaded"]);

const maxMetricsLinesToPreview = 2;

const query = ref("");

const path = ref(null);
const grid = ref("object_pass");
const grid_key = ref(null);
const transition_key = ref(null);
const callback_params = ref(null);
const row = ref(null);
const modal = ref(null);

const metrics = ref({});
const showingAllMetrics = ref(false);
const activeFilter = ref("");
const horizontalFiltersContent = ref({});
const headerTitleField = ref({});
const expansionItems = ref([]); // tree sctructure data
const expansionItemRefs = ref([]);
const currentTab = ref("");
const hideEmptyValues = ref(true);

const store = useStore();
const router = useRouter();

const initializeGridsForEdit = () => {
  store.commit("initialGridsState", { grid: "object_pass", attr: {} });
  store.commit("initialGridsState", { grid: "buildings", attr: {} });
  store.commit("initialGridsState", { grid: "floors", attr: {} });
  store.commit("initialGridsState", { grid: "rooms", attr: {} });
  store.commit("initialGridsState", { grid: "users", attr: {} });
  store.commit("initialGridsState", { grid: "companies", attr: {} });
  store.commit("initialGridsState", { grid: "issues", attr: {} });
  store.commit("initialGridsState", { grid: "ppr_equipments", attr: {} });
};

initializeGridsForEdit();

provide("topParentId", metrics.value.id);

const chunkedMetrics = computed(() => {
  const fields = metrics.value?.fields || [];

  return _.chunk(fields, 5);
});

const filtersPath = computed(
  () => `/api/v3/tech_passport/filters?id=${props.objectId}&template_id=${props.templateId}`,
);
const horizontalFiltersFieldsPathParams = computed(() => {
  return {
    main: "api/v3/tech_passport/filters/",
    id: props.objectId,
    pathOptions: {
      parent_template_id: props.templateId,
      template_id: "getFromChildComponent::filter.template_id",
    },
  };
});

const iconsByTypes = computed(() => {
  return {
    Building: "apartment",
    Floor: "meeting_room",
    Room: "chair",
    "Ppr::Equipment": "construction",
  };
});

const currentLocales = computed(() => locales.value.facility_dashboard[currentLocale.value].sections);

const searchLabel = computed(() => (currentLocale.value === "ru" ? "Поиск" : "Search"));

const loadMetrics = async () => {
  try {
    const response = await backend.index(
      `/api/v3/tech_passport/objects/${props.objectId}?template_id=${props.templateId}&filters=${JSON.stringify(
        horizontalFiltersContent.value,
      )}&query=${query.value}`,
    );

    metrics.value = response.data.blocks.find(block => block.type === "description");

    const headerBlockFields = response.data.blocks.find(block => block.type === "header").fields;
    headerTitleField.value = headerBlockFields.find(field => field.label === "title");
  } catch (error) {
    await handleError(error);
  }
};

const loadExpansionItems = async () => {
  try {
    const response = await backend.index(
      `/api/v3/tech_passport/objects?parent_template_id=${props.templateId}&parent_id=${
        props.objectId
      }&filters=${JSON.stringify(horizontalFiltersContent.value)}&query=${query.value}`,
    );
    expansionItems.value = response.data;
    if (expansionItems.value?.length) currentTab.value = expansionItems.value[0].type;
  } catch (error) {
    await handleError(error);
  }
};

const generateHorizontalFiltersObject = val => {
  if (val?.answers?.length) {
    horizontalFiltersContent.value = {
      type: val.type,
      fields: val.answers.map(elem => {
        if ((elem.type === "dropdown" || elem.type === "radio") && elem.inclusion) {
          return {
            field_id: elem.field_id,
            inclusion: elem.inclusion,
          };
        } else if (elem.type === "integer" && elem.min !== undefined && elem.max !== undefined) {
          return {
            field_id: elem.field_id,
            min: elem.min,
            max: elem.max,
          };
        }
      }),
    };
    activeFilter.value = val.type;
  } else {
    horizontalFiltersContent.value = {};
    activeFilter.value = "";
  }
};

// Close all items of expansion items
const closeExpansionList = () => {
  expansionItemRefs.value.forEach(el => {
    if (el) el.toggleExpansionItem("toggleOnRoot");
  });
};

const ItemType = {
  Building: "building",
  Floor: "floor",
  Room: "room",
};

const getRowData = async (path, id) => {
  try {
    return (await backend.index(`api/v3/${path}/${id}`)).data;
  } catch (e) {
    await handleError(e);
  }
};

const openEditForm = async params => {
  grid.value = params.grid;
  path.value = `/api/v3/${params.path}`;
  transition_key.value = params.report_class;

  if (params.path === "tech_map") {
    grid.value = "ppr_equipments";
    path.value = "/api/v3/ppr/equipments";
    transition_key.value = "ppr_equipment";
    row.value = await getRowData("ppr/equipments", params.id);
  } else {
    row.value = await getRowData(params.path, params.id);
  }

  if (params.grid === "rooms" || params.grid === "companies" || params.grid === "ppr_equipments") {
    row.value = row.value.common.data;
  }

  grid_key.value = "data";

  if (params.additional_params) {
    callback_params.value = params.additional_params;
  }

  if (params.grid === "tech_map") {
    row.value = row.value.common.data;
    row.value.show_tab = "tech_map";
    modal.value.openShow(row.value);
    return;
  }

  modal.value.openForm({ form_type: "edit", row, grid, callback_params });
};

const searchAllFilterReset = async () => {
  query.value = "";
  store.commit("updateQuery", { grid_name: grid.value, value: query.value });

  closeExpansionList();

  await loadMetrics(); // refetch metrics list
  await loadExpansionItems();
};

const updateSearchFilter = async val => {
  query.value = val;

  closeExpansionList();

  await loadMetrics(); // refetch metrics list
  await loadExpansionItems();
};

const onFilterResults = async val => {
  generateHorizontalFiltersObject(val); // create object with filters to fetch content (expansion list)
  closeExpansionList();

  await loadMetrics(); // refetch metrics list
  await loadExpansionItems(); // update list of expansions items (tree structure)
};

const isMetricsLineVisible = index => {
  const position = index + 1;
  return position <= maxMetricsLinesToPreview;
};

const metricsCardValue = field => {
  const isMeasureUnitShown = field.measure_unit && field.value;
  const value = isMeasureUnitShown ? `${field.value} ${field.measure_unit}` : field.value;
  if (field.type === "date") {
    return handleDateRow(field, "value");
  }
  if (field.value === 0) {
    return value;
  }
  return value || "-";
};

const openTelemetry = () => {
  if (props.objectId !== -1) {
    router.push({
      name: "telemetry",
      query: {
        building_id: props.objectId,
      },
    });
  }
};

onMounted(async () => {
  await loadMetrics();
  //emit("set-title", headerTitleField.value.value); // set dashboard title
  await loadExpansionItems();
  emit("loaded");
});
</script>

<style scoped lang="scss">
.facility-tab {
  padding-top: 15px;

  .search label {
    margin: 20px 0 !important;
    width: 50%;
  }

  .card {
    background: var(--dashboard-card-background);
    border-radius: 20px;
    position: relative;
  }

  .card-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: var(--dashboard-label-color);
  }

  &__floors-icon {
    margin-left: -20px;
  }

  .object-pass-blocks {
    display: flex;
    overflow: auto;
    margin-top: 30px;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .top {
    background: var(--dashboard-card-background);
    border-radius: 20px;
    width: 100%;
    display: flex;
    height: auto;

    .card-separator {
      border: 1px dashed #e8e9eb;
    }

    .common-info {
      width: 100%;
      padding: 20px;
      height: auto;

      display: flex;

      .card-button {
        margin-top: 10px;
      }

      .card-image {
        width: 14%;
        margin-right: 10px;
      }

      .card-info {
        width: 100%;
        padding-left: 20px;
        display: grid;
        height: auto;

        .card-info-rows {
          height: auto;
          display: flex;
          flex-direction: row;
          gap: 15px;
          column-gap: 60px;
          flex-wrap: wrap;
        }

        .card-info-row {
          display: grid;
          width: 20%;
        }

        .title {
          font-style: normal;
          font-size: 14px;
          line-height: 14px;
          padding-bottom: 5px;

          color: #5b7aff;
        }

        .sub-title {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;

          color: var(--dashboard-label-color);

          .field-value-attachment {
            color: #3d4c63;
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .measuring-meters {
      width: 23%;
      .card {
        padding: 20px;
        height: 200px !important;

        .card-image {
          margin-top: 15px;
          height: 85px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .telemetry {
      width: 23%;
      .card {
        padding: 20px;
        height: 200px !important;

        .card-image {
          margin-top: 15px;
          height: 85px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .expenses {
      padding: 20px;
      height: 200px;
      width: 50%;
      position: relative;
      margin-top: 0px;

      p {
        text-align: center;
        display: block;
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        height: fit-content;
        width: 80%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        color: var(--dashboard-label-color);
      }
    }
  }

  .object-pass-left-block {
    width: 520px;
    margin-bottom: 20px;

    .object-pass-left-block.footer {
      display: flex;
    }

    .seasonal_works {
      padding: 20px;
      height: 250px;
      width: 200px;
      margin-left: 30px;

      .card-image {
        margin-top: 15px;
        margin-bottom: 20px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.card-image {
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.card-button {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  /*width: 100%;*/
  text-align: center;

  button {
    height: 34px;
    width: 100%;
    max-width: 150px;
    border-radius: 8px;
    background-color: #5b7aff10;
    color: #5b7aff;
    padding-left: 40px;
    padding-right: 40px;

    .q-btn__wrapper {
      min-height: initial !important;
    }

    .q-btn__content {
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .section-name {
    font-weight: 400;
    font-size: 16px;
    color: var(--facility-dashboard-section-name-color);
  }

  .metrics-cards {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    .metrics-card {
      flex-grow: 1;
      padding: 16px 30px;
      background: var(--facility-dashboard-metrics-card-background);
      color: var(--facility-dashboard-metrics-card-color);
      border: 1px solid #dfdfe9;
      border-radius: 20px;
    }

    .metrics-card__label {
      text-transform: capitalize;
    }
  }

  .metrics-button {
    text-align: center;
    padding-top: 10px;

    a {
      color: #5b7aff;
    }
  }

  .hide-empty-values {
    display: flex;
    justify-content: space-between;
    color: var(--facility-dashboard-metrics-card-color);
  }
}

.horizontal-filters {
  margin-top: 20px;
}
</style>
